<template>
    <section class="contact-section">
        <div class="container my-container">
            <div class="contact-content">
                <div class="row no-gutters">
                    <div class="col-md-6 contact-half-container">
                        <h5>Contact Us</h5>
                        <p class="title">We would love to hear from you.</p>
                        <p class="normal-text">Please email us with any question or comments you may have about our website or company. Also, please visit our <span>FAQ (Frequently Asked Questions)</span> page where you can find useful information about the website or the company. Thank you.</p>
                        <div class="mb-4 contact-form-container">
                            <label for="user_name" class="form-label">Name</label>
                            <input v-model="form.name" type="text" id="user_pass" placeholder="Enter name" :class="formControl(v$.form.name)" required :disabled="loading">
                            <div v-if="v$.form.name.$error" class="invalid-feedback">
                                <span>{{ errorMessage(v$.form.name.$errors[0].$validator, 'Name') }}</span>
                            </div>
                        </div>
                        <div class="mb-3 contact-form-container">
                            <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                            <textarea v-model="form.message" id="exampleFormControlTextarea1" rows="3" :class="formControl(v$.form.message)" required :disabled="loading"></textarea>
                            <div v-if="v$.form.message.$error" class="invalid-feedback">
                                <span>{{ errorMessage(v$.form.message.$errors[0].$validator, 'Message') }}</span>
                            </div>
                            <div class="upload-grp">
                                <a href="" class="img-upload"></a>
                            </div>
                        </div>
                        <div class="mb-3 contact-upload">
                            <label for="formFile" class="form-label">Attachment</label>
                            <input ref="fileupload" class="form-control" type="file" id="formFile" @change="onFileChange" :disabled="loading">
                        </div>
                        <div class="d-grid">
                            <button class="btn btn-card" type="button" @click="submit" :disabled="loading">Submit</button>
                        </div>
                    </div>
                    <div class="col-md-6 half-content-container">
                        <img :src="require('../assets/img/g-contact.png')" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { defineComponent } from 'vue'
import { mapActions } from 'vuex';

export default defineComponent({
data() {
        return {
            loading: false,
            form: {
                name: null,
                message: null,
                attachment: null
            }
        };
    },
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    validations () {
        return {
            form: {
                name: { required },
                message: { required },
                attachment: {}
            }
        };
    },
    methods: {
        async submit() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) return;
            try {
                this.loading = true;
                await this.createContactRequest(this.form);
                this.form.name = null;
                this.form.message = null;
                this.form.attachment = null;
                this.$refs.fileupload.value = null;
                this.v$.form.$reset();
                this.$toast('Successfully sent!', {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#6C782A'
                    },
                    slot: '<i class="fa fa-check"></i>'
                });
            } catch(err) {
                this.$toast(err.message, {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#ce4f4f'
                    },
                    slot: '<i class="fa fa-exclamation"></i>'
                });
            } finally {
                this.loading = false;
            }
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            const reader = new FileReader()
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
                this.form.attachment = reader.result;
            };
        },
        errorMessage(type, name) {
            switch(type) {
                case 'required': return `${name} is required`;
            }
        },
        formControl(form) {
            return {
                'form-control': true,
                'is-invalid': form.$dirty && form.$invalid,
                'is-valid': form.$dirty && !form.$invalid
            };
        },
        ...mapActions('contact-us', { createContactRequest: 'create' })
    }
})
</script>